<template>
  <v-dialog
    v-model="show"
    @click:outside="fechar"
    @keydown.esc="fechar"
    width="85%"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-2">
        <span class="px-2">
          {{ $tc("global.contasReceber") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-row class="pt pb-4">
          <v-col cols="4">
            <SearchClientes :readonly="cliente != null" class="mr-2" :cliente_nome="cliente_nome" :cliente_id="cliente_id" :cliente_sync.sync="selectedCliente" />
          </v-col>
          <v-col cols="3" v-if="selectedCliente">
            <v-card class="pa-2">
              <span
                >{{
                  this.$tc("global.total") + " " + this.$tc("global.debito", 2)
                }}:
              </span>
              <Currency
                :moeda_sigla="selectedCliente.moeda_sigla"
                :valor="total_debitos_cliente"
              />
            </v-card>
          </v-col>
          <v-col cols="3" v-if="selectedCliente">
            <v-card class="pa-2">
              <span
                >{{
                  this.$tc("global.total") +
                  " " +
                  this.$tc("global.credito", 2)
                }}:
              </span>
              <Currency
                :moeda_sigla="selectedCliente.moeda_sigla"
                :valor="total_creditos_cliente"
              />
            </v-card>
          </v-col>
          <v-col cols="2" v-if="selectedCliente">
            <v-card class="pa-2">
              <span>{{ this.$tc("global.saldo") }}: </span>
              <Currency
                :moeda_sigla="selectedCliente.moeda_sigla"
                :valor="saldo_cliente"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-divider v-if="!loading && selectedCliente"></v-divider>
        <v-data-table
          v-if="!loading && selectedCliente"
          v-model="contasSelected"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="contas"
          item-key="id"
          sort-by="id"
          @click:row="goToContaReceber"
          show-select
          :items-per-page="10"
          class="data-tables"
          :class="{ 'data-tables__row-click': PClienteContasReceberEditar }"
          :item-class="row_classes"
        >
          <template v-slot:item.fatura_id="{ item }">
            <div class="flex" v-if="item.fatura_id">
              {{ item.fatura_ponto }}
              {{ item.fatura_numero.toString().padStart(7, "0") }}
            </div>
            <span v-else>{{ $tc("global.semFatura") }}</span>
          </template>
          <template v-slot:item.data_vencimento="{ item }">
            <div>
              <div v-if="item.data_vencimento">
                {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
              </div>
            </div>
          </template>
          <template v-slot:item.valor="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$' && item.valor"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.valor | guarani }}</div>
            </div>

            <div class="d-flex justify-space-between" v-else-if="item.valor">
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.valor | currency }}</div>
            </div>
          </template>

          <template v-slot:item.juros="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$' && item.juros"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.juros | guarani }}</div>
            </div>
            <div class="d-flex justify-space-between" v-else-if="item.juros">
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.juros | currency }}</div>
            </div>
          </template>

          <template v-slot:item.desconto="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$' && item.desconto"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.desconto | guarani }}</div>
            </div>
            <div class="d-flex justify-space-between" v-else-if="item.desconto">
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.desconto | currency }}</div>
            </div>
          </template>

          <template v-slot:item.valor_pago="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$'"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.valor_pago | guarani }}</div>
            </div>
            <div
              class="d-flex justify-space-between"
              v-else-if="item.valor_pago"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.valor_pago | currency }}</div>
            </div>
          </template>

          <template v-slot:item.saldo="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$' && item.saldo"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.saldo | guarani }}</div>
            </div>
            <div class="d-flex justify-space-between" v-else-if="item.saldo">
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.saldo | currency }}</div>
            </div>
          </template>

          <template v-slot:item.multa="{ item }">
            <div
              class="d-flex justify-space-between"
              v-if="item.moeda_sigla == 'G$' && item.multa"
            >
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.multa | guarani }}</div>
            </div>
            <div class="d-flex justify-space-between" v-else>
              <div>{{ item.moeda_sigla }}</div>
              <div>{{ item.multa | currency }}</div>
            </div>
          </template>

          <template v-slot:item.cliente_nome="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.cliente_nome | excerpt(24) }}
                </div>
              </template>
              <span>{{ item.cliente_nome }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          :disabled="disableConfirmar"
          class="white--text"
          @click="enviarItensParaBaixa"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $tc("global.confirmar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogContasReceberCliente
      v-if="dialogConta"
      :dialogConta.sync="dialogConta"
      :conta="conta"
      @fetch-contas="getContasCliente"
    />
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import { contasReceberCliente } from "@/api/contas-receber/contas_receber.js";
import { mapGetters } from "vuex";

export default {
  name: "ContasReceberEmLoteAf",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
    },
  },

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
    Currency: () => import("@/components/global/Currency.vue"),
    DialogContasReceberCliente: () =>
      import(
        "@/views/dashboard/clientes/components/DialogContasReceberCliente.vue"
      ),
  },

  mounted() {
    if (this.cliente) {
      this.cliente_nome = this.cliente.nome;
      this.cliente_id = this.cliente.id;
    }
  },

  data() {
    return {
      contas: [],
      loading: false,
      search: "",
      selectedCliente: null,
      contasSelected: [],
      total_creditos_cliente: 0,
      dialogConta: false,
      cliente_nome: null,
      cliente_id: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PClienteContasReceberEditar() {
      return this.getAccess("ClienteContas", "editar");
    },

    disableConfirmar() {
      return this.contasSelected.length == 0;
    },

    saldo_cliente() {
      let saldo = 0;
      if (this.selectedCliente) {
        saldo =
          Number(this.total_creditos_cliente) -
          Number(this.total_debitos_cliente);
      }
      return saldo;
    },

    total_debitos_cliente() {
      let total = 0;
      if (this.contas.length > 0) {
        this.contas.forEach((conta) => {
          total += Number(conta.saldo);
        });
      }
      return total;
    },

    headers() {
      return [
        {
          text: "ID",
          width: "40px",
          value: "id",
          divider: true,
        },
        {
          text: this.$tc("global.fatura"),
          width: "100px",
          align: "center",
          value: "fatura_id",
          divider: true,
        },
        {
          text: this.$tc("global.vencimento"),
          width: "120px",
          align: "center",
          value: "data_vencimento",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          width: "290px",
          value: "descricao",
          divider: true,
        },
        {
          text: this.$tc("global.ordem"),
          width: "90px",
          align: "center",
          value: "ordem",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          width: "120px",
          align: "right",
          value: "valor",
          divider: true,
        },
        {
          text: this.$tc("global.juros"),
          width: "100px",
          align: "right",
          value: "juros",
          divider: true,
        },

        {
          text: this.$tc("global.multa"),
          width: "100px",
          align: "right",
          value: "multa",
          divider: true,
        },

        {
          text: this.$tc("global.desconto"),
          width: "115px",
          align: "right",
          value: "desconto",
          divider: true,
        },
        {
          text: this.$tc("global.pago"),
          width: "110px",
          align: "right",
          value: "valor_pago",
          divider: true,
        },
        {
          text: this.$tc("global.saldo"),
          width: "110px",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },

  methods: {
    enviarItensParaBaixa() {
      let baixaItens = [];
      for (let i = 0; i < this.contasSelected.length; i++) {
        let conta = this.contasSelected[i];
        console.log(conta);
        let baixaItem = {
          id: conta.id,
          tipo: "conta_receber",
          cliente_fornecedor: conta.cliente_nome,
          cliente: { id: conta.cliente_id, nome: conta.cliente_nome },
          condicao_pagamento: 1,
          valor: conta.saldo,
          tipo_af: "E",
          moeda_sigla: conta.moeda_sigla,
          conta: conta,
        };
        baixaItens.push(baixaItem);
      }
      this.$emit("add", baixaItens);
      this.fechar();
    },
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      }
    },
    goToContaReceber(item) {
      if (!this.PClienteContasReceberEditar) return;
      if (item && Number(item.saldo) > 0) {
        this.conta = { ...item };
        this.dialogConta = true;
      } else {
        this.$toast.error(this.$tc("global.contaPaga"));
      }
    },
    getContasCliente() {
      this.loading = true;
      contasReceberCliente(this.selectedCliente.id)
        .then((response) => {
          this.contas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    fechar() {
      this.$emit("update:show", false);
    },

    setValoresClientes(cliente) {
      this.total_creditos_cliente = cliente.credito;
    },
  },

  watch: {
    selectedCliente(val) {
      if (val) {
        this.getContasCliente();
        this.setValoresClientes(val);
      }
    },
  },
};
</script>

<style></style>
